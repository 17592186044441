<template>
  <div>
    <div :class="padding" v-show="!hideTabs" class="tab-bar ml-3">
    <v-btn small :disabled="tab.disabled" v-for="(tab, index) in tabs" :key="`tab-button-${index}`" :ripple="false"
    :style="currentTab == index ? `width: ${((11 * tab.name.length + 50) + ((tab.icon != null && tab.icon.length) > 0 ? 30 : 0))}px; transition: all 250ms linear; pointer-events: none` : `width: ${((11 * tab.name.length + 20) + ((tab.icon != null && tab.icon.length) > 0 ? 20 : 0))}px; transition: all 250ms linear; color: var(--v-primary-base)`" :color="currentTab == index ? 'primary' : '#E5E5E5'" v-on:click="switchTab(index)" class="elevation-0 mr-3 tab-button">
    <v-icon size="18" class="mr-1" v-if="tab.icon != null && tab.icon.length > 0">{{tab.icon}}</v-icon>
    {{tab.name}}
  </v-btn>
  </div>

  <v-tabs-items  v-model="currentTab" style="background-color: transparent!important;" :style="fullHeight ? 'height: calc(100vh - 103px); overflow: hidden' : ''">
<v-tab-item :eager="eager" :class="padding" style="background-color: transparent!important" v-for="(tab, index) in tabs" :key="`tab-content-${index}`">
  <slot :name="tab.id"/>

</v-tab-item>
</v-tabs-items>
  </div>
</template>

<script>

export default {
  props: {
    eager: {
      type: Boolean, 
      default: false
    },
    fullHeight: {
      default: false
    },
    hideTabs: {
      type: Boolean,
      default: false
    },
    tabs: {
      type: Array,
      required: true
    },
    padding: {
      type: String,
      default: ''
    },
    refresh: {
      type: Boolean,
    },
  },

  watch: {
    currentTab() {
      if (this.tabs[this.currentTab] == null) return (this.switchTab(0))
    },
    refresh: {
      handler() {
        if (this.currentTab != 0) this.currentTab -= 1
      }, immediate: true, deep: true
    },
    tabs: {
      handler() {

        if (this.tabs[this.currentTab] == null) return (this.switchTab(0))

        if (this.tabs[this.currentTab] != null && this.tabs[this.currentTab].disabled != null && this.tabs[this.currentTab].disabled == true) {
          var index = this.tabs.findIndex(tab => tab.disabled == false)

          setTimeout(() => {(this.switchTab(index))}, 1)
        }
      }, immediate: true, deep: true
    },

    hideTabs: {
      handler() {
        if (this.hideTabs) this.currentTab = 0
      }, immediate: true, deep: true
    }
  },

  data() {
    return {
      currentTab: 0
    }
  },

  methods: {
    switchTab(index){
      this.currentTab = index
    }
  }
}
</script>

<style scoped>

  .tab-button {
    height: 28px!important;
    font-size: 13px!important;
    margin-left: 3px; margin-right: 3px;
  }

  .tab-bar {
    overflow: hidden; white-space: nowrap; overflow-x: scroll
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
.tab-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tab-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
