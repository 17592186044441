<template>
<div>

    <div  class="sidebar" :style="`height: ${height}; max-height: ${maxHeight}`" :class="showSidebar ? 'sidebar-open' : 'sidebar-close'" >

<v-card class="content-page" v-click-outside="clickOutsideEvent">
    <div style="position: relative">
    <v-row class="ma-0 pt-2 pb-2 ml-2" align="center">
      <IconButton v-on:click="toggleSidebar()" dense class="mt-1 ml-1" icon="mdi-close"/>
   <span class="ml-2" style="position: relative; font-size: 20px">
    <b>   {{name}}</b>
   </span>
   <v-spacer/>

   <div class="mr-2">
       <slot name="header-space"/>
       </div>
    </v-row>
    <!-- <v-divider /> -->

    <div class="content-scroll" :style="`height: calc(${height} - 41px);
    max-height: calc(${maxHeight} - 41px)!important;`" >
        <slot style="position: relative" name="content"/>
</div>
</div>
        </v-card></div>

        </div>
</template>

<script>
import IconButton from '../button/IconButton.vue';

export default {
    props: {
        name: {
            default: ''
        },
        toggle: {
            default: false
        },
        height: {
            default: '85%'
        },
        maxHeight: {
            default: '650px'
        }
    },
    components: {
      IconButton
    },
    // props: ['name', 'toggle'],
data() {
    return {
        showSidebar: false,
        toggleDebounce: false,
    }
},

watch: {
    toggle() {
      // console.l("TOGGLE", this.toggle)
        if (this.showSidebar != this.toggle) this.toggleSidebar(this.toggle);
    },

    showSidebar() {
        // // console.l("WATCH", this.toggle, this.showSidebar)
        if (this.showSidebar == false) this.$emit('close', this.showSidebar)
    }
},

methods: {
    toggleSidebar() {
        // // console.l("BUTTON TOGGLE", status)
        this.showSidebar = !this.showSidebar
        this.toggleDebounce = true;

        setTimeout(() => {this.toggleDebounce = false}, 15)
    },
    clickOutsideEvent() {

        if (this.showSidebar == true && !this.toggleDebounce) this.toggleSidebar()

    }

}
}
</script>
<style>
.sidebar {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 320px;
    z-index: 10000;
    background-color: transparent;
    overflow: -moz-scrollbars-none; -ms-overflow-style: none;
}

    .sidebar ::-webkit-scrollbar { width: 0 !important;  }  

.sidebar-close {
    width: 0px;
    right: 0px;
    transition: width 200ms ease-in;

}
.sidebar-open {
    right: 0px;
    transition: width 200ms ease-out;
}

.content-page {
     position: absolute;
     height: 100%;
     left: 0px;
     width: 300px!important;
     min-width: 300px!important;
     max-width: 300px!important;
     background-color: white;
     border-radius: 10px!important;
}
.headline-underline {
  position: absolute;

  bottom: 0px;
  background-color: var(--v-primary-base);
  width: 100%;
  height: 4px;
  border-radius: 2px;
}
.content-scroll {
    position: relative;
    overflow-y: scroll;
    height: 100%;
    min-height: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px
}
</style>
