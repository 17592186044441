<template>
  <div>
    <DialogTemplate
      icon="mdi-link-variant"
      v-on:close="$emit('close')"
      :title="$t(`tools.project.share.title.${shareObj.type}`)"
    >
      <template v-slot:content>
        <div oncontextmenu="return false;" style="user-select: none">
          <v-row
            class="ma-0"
            align="start"
            style="
              position: relative;
              background-color: #f0f0f0;
              border: 1px solid #c0c0c0;
              padding: 10px;
              border-radius: 10px;
            "
          >
            <div
              v-if="shareObj.type == 0 || shareObj.type == 2"
              style="
                background-color: rgba(140, 140, 140, 0.6);
                overflow: hidden !important;
                border-radius: 10px;
                aspect-ratio: 16/9;
                height: 90px;
                position: relative;
              "
            >
              <img
                height="90"
                style="user-drag: none; aspect-ratio: 16/9"
                :src="shareObj.thumbnailUrl"
              />
              <div
                v-show="shareObj.type == 2"
                style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
              >
                <v-icon size="30">mdi-play-circle</v-icon>
              </div>
            </div>
            <div
              v-else-if="shareObj.type == 1"
              style="
                background-color: rgba(140, 140, 140, 0.6);
                position: relative;
                overflow: hidden !important;
                border-radius: 10px;
                width: 200px;
                height: 113px;
              "
            >
              <img
                width="200"
                style="
                  user-drag: none;
                  border-radius: 10px;
                  position: absolute;
                  clip: rect(0px, 100px, 113px, 0px) !important;
                "
                height="113"
                :src="shareObj.thumbnailAUrl"
              />
              <img
                width="200"
                style="
                  user-drag: none;
                  border-radius: 10px;
                  position: absolute;
                  clip: rect(0px, 200px, 113px, 100px) !important;
                "
                height="113"
                :src="shareObj.thumbnailBUrl"
              />
              <div
                style="
                  position: absolute;
                  z-index: 10;
                  height: 100%;
                  left: 50%;
                  width: 5px;
                  background-color: #f0f0f0;
                "
              ></div>
            </div>

            <div class="ml-3">
              <span class="font-weight-bold" style="font-size: 16px">
                {{ $helper.shortenText(shareObj.name, 32) }}</span
              ><br />
              <span style="font-size: 14px"
                ><span>{{ $t('tools.project.share.preset') }}: </span
                ><b class="ml-1">{{
                  $helper.formatPresetName(shareObj.presetId)
                }}</b></span
              ><br />
              <div
                v-if="shareObj.type == 1 || shareObj.type == 2"
                style="font-size: 14px"
              >
                <span class="mr-1">{{
                  $helper.formatDateRange(shareObj.dateA, shareObj.dateB)
                }}</span>
              </div>
              <!-- <span style="font-size: 14px"><span style="font-size: 14px">Gültig bis: </span><b class="ml-1">{{$helper.formatDate(shareObj.image.expiresAt, false, false)}}</b></span><br> -->
              <!-- <v-chip color="rgba(255, 255, 255, .6)" class="mt-1" small style="pointer-events: none">Kamera Nr. {{shareObj.deviceId}}</v-chip><br> -->
              <v-row
                class="ma-0 mt-0"
                style="
                  padding-top: 2px;
                  opacity: 0.7;
                  position: absolute;
                  bottom: 7px;
                "
                align="center"
              >
                <div>
                  <v-icon size="16" color="black">
                    {{
                      $t(`tools.project.share.type.${shareObj.type}.icon`)
                    }}</v-icon
                  >
                  <span
                    class="ml-1 black--text font-weight-bold"
                    style="font-size: 13px"
                    >{{
                      $t(`tools.project.share.type.${shareObj.type}.text`)
                    }}</span
                  >
                </div>
              </v-row>
            </div>
          </v-row>

          <!-- <div class="mt-3 mb-1"><b >Link zum Teilen: </b></div> -->
          <v-row v-if="shareObj.type == 0" class="ma-0 mt-3" align="center">
            <TextButton
              class="mr-2"
              buttonColor="#F0F0F0"
              :loading="loadingDownload == 1"
              dense
              v-on:click="downloadImage(1)"
              icon="mdi-download"
              :text="$t('tools.project.share.button.downloadImage')"
            />
            <TextButton
              buttonColor="#F0F0F0"
              :loading="loadingDownload == 2"
              dense
              v-on:click="downloadImage(2)"
              icon="mdi-download"
              :text="$t('tools.project.share.button.downloadDayTimelapse')"
            />
          </v-row>

          <v-row v-if="shareObj.type == 1" class="ma-0 mt-3" align="center">
            <TextButton
              class="mr-2"
              buttonColor="#F0F0F0"
              :loading="loadingDownload == 1"
              dense
              v-on:click="downloadImage(1)"
              icon="mdi-download"
              :text="$t('tools.project.share.button.type-1.image1')"
            />
            <TextButton
              buttonColor="#F0F0F0"
              :loading="loadingDownload == 2"
              dense
              v-on:click="downloadImage(2)"
              icon="mdi-download"
              :text="$t('tools.project.share.button.type-1.image2')"
            />
          </v-row>

          <v-row v-if="shareObj.type == 2" class="ma-0 mt-3" align="center">
            <TextButton
              class="mr-2"
              buttonColor="#F0F0F0"
              :loading="loadingDownload == 2"
              dense
              v-on:click="downloadImage(2)"
              icon="mdi-download"
              :text="$t('tools.project.share.button.downloadCustomTimelpase')"
            />
            <TextButton
              class="mr-2"
              buttonColor="#F0F0F0"
              :loading="loadingDownload == 1"
              dense
              v-on:click="downloadImage(1)"
              icon="mdi-download"
              :text="$t('tools.project.share.button.downloadTimelapsePreview')"
            />
          </v-row>

          <CopyField class="mt-4" :link="sharableLink" :loading="loadingLink" />
          <div class="grey--text ml-4" style="font-size: 13px">
            {{ $t('tools.project.share.descriptions.share') }}
          </div>

          <div v-if="shareObj.type == 2">
            <CopyField
              class="mt-3"
              v-if="url != null"
              :link="`<iframe src=&quot;${$share.embedLink(
                `${shareObj.shareUrl}`,
                $route.params.lang,
                true,
              )}&quot; width=&quot;100%&quot; style=&quot;aspect-ratio: 16/9&quot;>`"
            />
            <div class="grey--text ml-4" style="font-size: 13px">
              {{ $t('tools.project.share.descriptions.embed') }}
            </div>
          </div>

          <!-- <span :style="loadingLink ? 'opacity: 0' : ''"  class="grey--text pl-4" style="font-size: 13px"> Klicken, um den Link zu kopieren.</span> -->
        </div>

        <div v-if="shareObj.type == 0 && dynamic != false">
          <v-divider v-show="$store.getters.noDevices != true && (isOwner  || publicLinkToggle)" class="mt-3 mb-3" />

          <v-row class="ma-0" align="center" v-show="isOwner">
            <v-switch
              v-show="isOwner && $store.getters.noDevices != true "
              v-on:change="toggleSwitch"
              v-model="publicLinkToggle"
              hide-details
              small
              style="margin-top: 5px"
              class="mb-2"
              :ripple="false"
            >
              <template v-slot:label>
                <v-row class="ma-0" align="center">
                  <span
                    style="font-size: 15px"
                    class="ml-2 font-weight-bold"
                    :class="publicLinkToggle ? ' primary--text' : 'black--text'"
                    >{{ $t('tools.project.share.embedPreset.title') }}</span
                  >

                  <v-icon
                    v-show="publicLinkToggle == true && !loadingDynUrl"
                    size="17"
                    color="primary"
                    class="ml-2"
                    style="margin-top: 1px"
                    >mdi-earth</v-icon
                  >

                  <v-progress-circular
                    v-show="loadingDynUrl"
                    indeterminate
                    width="2"
                    size="16"
                    class="ml-2"
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-switch>
            <v-spacer />
            <!-- <IconButton  v-on:click="$router.push('project_settings_embed')" dense icon="mdi-cog"/> -->
          </v-row>
          <div v-show="publicLinkToggle && loadingDynUrl == false">
            <v-alert
              text
              filled
              dense
              class="pt-2 pb-2 mt-2 font-weight-bold"
              style="font-size: 14px"
              color="primary"
            >
              <v-row class="ma-0" align="center">
                <v-icon
                  size="17"
                  color="primary"
                  class="mr-2"
                  style="margin-top: 1px"
                  >mdi-earth
                </v-icon>
                {{ $t('tools.project.share.embedPreset.info.title') }}
              </v-row>
              <div class="ml-6 font-weight-regular" style="font-size: 13.5px">
                {{ $t('tools.project.share.embedPreset.info.text') }}
              </div>
            </v-alert>

            <CopyField :link="dynamicShareUrl" :loading="loadingDynUrl" />
            <div class="grey--text ml-4" style="font-size: 13px">
              {{ $t('tools.project.share.descriptions.dynamicLink') }}
            </div>

            <CopyField
              class="mt-3"
              v-if="shareObj.shareUrl != null"
              :link="`<iframe src=&quot;${$share.embedLink(
                `link=${dynamicShareUrl}`,
                $route.params.lang,
              )}&quot; width=&quot;100%&quot; style=&quot;aspect-ratio: 16/9&quot;>`"
              :loading="loadingDynUrl"
            />
            <div class="grey--text ml-4" style="font-size: 13px">
              {{ $t('tools.project.share.descriptions.embed') }}
            </div>
          </div>
        </div>
      </template>
    </DialogTemplate>
  </div>
</template>

<script>
import DialogTemplate from '@components/dialog/DialogTemplate.vue';

import TextButton from '@components/button/TextButton.vue';
import CopyField from '../../../../../ui-components/src/components/text/CopyField.vue';
import {config} from '@/server.config.js';
// import IconButton from "../../../../../ui-components/src/components/button/IconButton.vue";
export default {
  name: 'ShareDialog',
  props: {
    url: {
      type: String,
      default: '',
    },
    shareObj: {
      type: Object,
    },

    dynamic: {
      type: Boolean,
      default: true,
    },
  },
  components: {DialogTemplate, TextButton, CopyField},

  data() {
    return {
      config,
      publicLinkToggle: false,
      loadingLink: true,
      sharableLink: '',
      loadingDownload: false,
      loadingDynUrl: false,
    };
  },

  computed: {
    dynamicShareUrl() {
      return `https://storage.googleapis.com/${
        this.config[process.env.VUE_APP_ENV].publicStorage
      }/${this.$route.params.projectId}/${this.shareObj.deviceId}/${
        this.shareObj.presetId
      }/latest.jpg`;
    },
    isOwner() {
      return this.$auth.isProjectOwner();
    },
  },

  watch: {
    shareObj: {
      async handler() {
        if (this.shareObj.shareUrl == null) return;

        this.copied = false;
        this.sharableLink = '';
        this.loadingLink = true;

        this.publicLinkToggle = this.shareObj.isLatestImagePublic;
        if (this.publicLinkToggle == true) this.loadingDynUrl = false;

        setTimeout(async () => {
          var imageDate;

          if (this.shareObj.shareType == 'image') {
            imageDate = this.shareObj.date;
          } else {
            imageDate = this.$helper.formatDateRange(
              this.shareObj.dateA,
              this.shareObj.dateA,
            );
          }
          var thumbUrl;
          if (this.shareObj.shareType == 'image')
            thumbUrl = this.shareObj.thumbnailUrl;
          else thumbUrl = this.shareObj.thumbnailAUrl;

          const res = await this.$share.link(
            this.shareObj.shareUrl,
            this.$t(
              `tools.project.share.type.${this.shareObj.type}.previewText`,
              {date: imageDate},
            ),
            'AlinoCam',
            this.shareObj.shareType,
            thumbUrl,
          );

          if (res != null && res.shortLink != null) {
            this.sharableLink = res.shortLink;
          } else {
            this.sharableLink = null;
            this.$toast.error(this.$t('tools.project.share.error.linkFailure'));
          }
          this.loadingLink = false;
        }, 500);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async toggleSwitch() {
      if (this.shareObj.type != 0 || this.dynamic != true) return;

      // if (this.publicLinkToggle == false) return;
      this.loadingDynUrl = true;

      var resp = await this.$api.project.publicityStatus(
        this.$route.params.projectId,
        this.shareObj.deviceId,
        this.shareObj.presetId,
        this.publicLinkToggle,
      );

      if (resp == null || resp.success == false) {
        setTimeout(() => {
          this.$toast.error('Es konnte kein dynamischer Link erstellt werden.');
          this.publicLinkToggle = false;
        }, 500);
      } else {
        await this.$api.project.latestImages(this.$route.params.projectId);

        this.loadingDynUrl = false;
      }
    },

    async downloadImage(type) {
      this.loadingDownload = type;

      try {
        if (this.shareObj.type == 0 || this.shareObj.type == 2) {
          if (type == 1) {
            await this.$helper.download(
              this.shareObj.imageUrl,
              `alinocam_image_${this.shareObj.name}`,
              'image/png',
              'png',
            );
          } else if (type == 2) {
            await this.$helper.download(
              this.shareObj.timelapseUrl,
              `alinocam_timelapse_${this.shareObj.name}`,
              'video/mp4',
              'mp4',
            );
          }
        } else if (this.shareObj.type == 1) {
          if (type == 1) {
            await this.$helper.download(
              this.shareObj.imageAUrl,
              `alinocam_image_${this.shareObj.name}_1`,
              'image/png',
              'png',
            );
          } else if (type == 2) {
            await this.$helper.download(
              this.shareObj.imageBUrl,
              `alinocam_image_${this.shareObj.name}_2`,
              'image/png',
              'png',
            );
          }
        }
      } catch {
        this.$toast.error(this.$t('tools.project.share.error.download'));
      }

      setTimeout(() => {
        this.loadingDownload = false;
      }, 500);
    },
  },
};
</script>
