<template>
  <div oncontextmenu="return false;" style="position: relative; height: 100%; overflow: hidden">
    <div v-if="presets != null && presets.length > 0" style="position: relative; height: 100%; width: 100%">
      <fullscreen v-model="toggleFullscreen" style="position: relative; height: 100%; width: 100%">
        <div class="recording-container">
          <!-- Zoom bar (righ corner); only displayed in singleRecording mode -->
          <div class="zoombar" v-show="singleRecording &&
      !$vuetify.breakpoint.xsOnly &&
      !$vuetify.breakpoint.mdOnly &&
      !toggleMotionPlay
      ">
            <v-row class="ma-0 ml-4 mr-2" style="padding-top: 2px" align="center">
              <v-icon style="margin-top: 1px">mdi-magnify-plus-outline</v-icon>
              <v-slider class="mr-1 ml-1" dense hide-details :min="10" :max="50" v-model="zoomLevel" />
            </v-row>
          </div>

          <!-- Motion bar (slot) -->
          <div class="motionbar" v-show="singleRecording && toggleMotionPlay == true
      ">
            <slot name="motionbar" />
          </div>

          <!-- Bottom navigation bar -->

          <div class="bottombar" :style="singleRecording ? 'width: 450px;' : 'width: 584px;'"
            v-show="toggleBottombar == true && selectedDates != null">
            <v-row class="ma-0 mr-2 ml-2" align="center" justify="center" style="height: 38px">
              <!-- Tab-like switcher -->
              <div v-show="!singleRecording" style="position: relative; width: 50%" class="pr-4">
                <v-btn v-for="(tab, index) in settingsTabs" :key="`tab-button-${index}`" :ripple="false" :style="currentDateTab == index
        ? `width: calc(${100 / settingsTabs.length + 8
        }% - 6px); transition: all 150ms linear; pointer-events: none`
        : `width: calc(${100 / settingsTabs.length - 8
        }% - 6px); transition: all 150ms linear; color: var(--v-primary-base)`
      " :color="currentDateTab == index ? 'primary' : '#E5E5E5'" v-on:click="currentDateTab = index"
                  class="elevation-0 tab-button">{{ tab.name }}</v-btn>
              </div>

              <v-spacer />

              <!-- Date/Daterange -->
              <span class="ml-6 grey--text" :class="singleRecording ? 'font-weight-bold' : ''" style="font-size: 15px">
                <span v-if="currentDateTab == 0">{{
                  this.$helper.formatDate(selectedDates.start)
                  }}</span>
                <span v-else>{{
                  this.$helper.formatDate(selectedDates.end)
                  }}</span>
              </span>

              <v-spacer v-show="singleRecording" />

              <IconButton v-on:click="toggleBottombar = false" dense class="ml-2" icon="mdi-close" />
            </v-row>

            <v-row class="ma-0 mr-3 ml-3" align="center" style="height: 38px">
              <!-- Previous image button -->
              <v-btn :disabled="prevImageTime == null" v-on:click="switchImage(prevImage)" small rounded depressed
                :ripple="false" color="#e1e1e1">
                <v-row class="ma-0 mr-2" align="center">
                  <v-icon size="18" class="mr-1">mdi-chevron-left</v-icon>
                  {{ $t('tools.project.recordings.buttons.prev') }}
                </v-row>
              </v-btn>
              <v-spacer />

              <!-- Next image button -->
              <v-btn :disabled="nextImageTime == null" v-on:click="switchImage(nextImage)" small rounded depressed
                :ripple="false" color="#e1e1e1">
                <v-row class="ma-0 ml-2" align="center">
                  {{ $t('tools.project.recordings.buttons.next') }}
                  <v-icon size="18" class="ml-1">mdi-chevron-right </v-icon>
                </v-row>
              </v-btn>
            </v-row>
          </div>

          <!-- TOOLBAR -->
          <div class="toolbar elevation-1" :style="singleRecording ? 'width: 645px' : 'width: 516px'">
            <v-row class="ma-0" align="center">
              <!-- Bottombar toggle-button -->
              <v-btn :disabled="presetError || loadingPresetDates" v-on:click="toggleBottombar = !toggleBottombar"
                :ripple="false" text tile>
                <v-icon size="21" v-if="toggleBottombar" color="primary">mdi-card-text</v-icon>
                <v-icon size="21" v-else>mdi-card-text-outline</v-icon>
              </v-btn>

              <!-- calendar/sidebar toggle-button -->
              <v-btn :disabled="presetError || loadingPresetDates" v-on:click="toggleCalendarbar = !toggleCalendarbar"
                :ripple="false" text tile>
                <v-icon size="21" v-if="toggleCalendarbar" color="primary">mdi-calendar-range</v-icon>
                <v-icon size="21" v-else>mdi-calendar-range</v-icon>
              </v-btn>

              <div v-show="singleRecording">
                <WeatherCard v-if="singleRecording" :date="selectedDates.start" :disabled="selectedDates == null ||
      selectedDates.start == null ||
      presetError ||
      toggleFullscreen ||
      loadingPresetDates ||
      this.project.general == null ||
      this.project.general.latitude == null
      " :coords="{
      lat: this.project.general.latitude || null,
      lng: this.project.general.longitude || null,
    }" />
              </div>
              <!-- Share button -->
              <v-btn :disabled="presetError || toggleFullscreen || loadingPresetDates
      " v-on:click="showShareDialog" :ripple="false" text tile>
                <v-icon size="21">mdi-link-variant</v-icon>
              </v-btn>

              <!-- Fullscreen button -->
              <v-btn :disabled="presetError" v-on:click="toggleFullscreen = !toggleFullscreen" :ripple="false" text
                tile>
                <v-icon size="21" v-if="toggleFullscreen">mdi-fullscreen-exit</v-icon>
                <v-icon size="21" v-else>mdi-fullscreen</v-icon>
              </v-btn>

              <!-- Preset Selector -->
              <div>
                <v-menu v-model="presetSelectorMenu" offset-y nudge-bottom="7">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-if="!presetError" class="preset-selector" :style="presets == null ||
        presets.length <= 1 ||
        toggleFullscreen
        ? 'pointer-events: none'
        : presetSelectorMenu
          ? 'background-color: #EEEEEE'
          : ''
      " v-bind="attrs" v-on="on">
                      <v-row class="ma-0" align="center" style="font-size: 15px">
                        <span style="font-size: 14px">{{ $t('tools.project.recordings.preset') }}:</span>
                        <span class="font-weight-bold ml-2">
                          <span v-if="presets && presets.length > 0">
                            {{
                            $helper.shortenText(
                            $helper.formatPresetName(
                            presets[selectedPresetIndex].presetId,
                            ),
                            21,
                            )
                            }}</span>
                        </span>

                        <v-spacer />
                        <v-icon class="mr-3" style="margin-top: 1px" :color="presetSelectorMenu ? 'primary' : ''" v-if="!loadingPresetDates &&
      presets &&
      presets.length > 1 &&
      !toggleFullscreen
      " size="20">
                          mdi-chevron-down
                        </v-icon>

                        <v-progress-circular class="mr-3" v-else-if="loadingPresetDates" indeterminate width="2"
                          size="18" color="primary" />
                      </v-row>
                    </div>

                    <div v-else class="error--text error-text">
                      <v-row class="ma-0" style="padding-bottom: 2px" align="center">
                        <v-icon color="error" size="18" class="mr-2">mdi-alert-circle</v-icon>
                        <b>{{
                          $t('tools.project.recordings.error.loading')
                          }}</b>
                        <v-spacer />
                        <IconButton :loading="loadingPresetData" v-on:click="reloadPresetData" icon="mdi-refresh"
                          dense />
                      </v-row>
                    </div>
                  </template>

                  <v-list nav width="260" style="max-height: 400px; overflow-y: scroll">
                    <v-list-item-group v-model="selectedPresetIndex">
                      <v-list-item :style="selectedPresetIndex == index
        ? 'cursor: default!important; pointer-events: none!important'
        : ''
      " dense :ripple="false" v-for="(preset, index) in presets" :key="index">
                        <v-list-item-title class="ml-1" :class="selectedPresetIndex == index
        ? 'primary--text font-weight-bold'
        : ''
      " style="font-size: 15px">
                          {{ $helper.formatPresetName(preset.presetId) }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>
            </v-row>
          </div>

          <div class="image-container">
            <div style="position: relative; height: 100%; width: 100%" v-if="!presetError">
              <slot name="image" />
            </div>

            <div v-else>
              <div class="no-preview">
                <v-row v-if="!loadingPresetData">
                  <v-icon size="20" class="mr-2" color="rgb(255, 255, 255)" style="opacity: 0.6">mdi-image-off-outline
                  </v-icon>
                  <span>{{
                    $t('tools.project.recordings.error.noPreview')
                    }}</span>
                </v-row>
              </div>
            </div>
          </div>

          <!-- Side Dialog -->
          <SideDialog v-if="!loadingPresetDates" :toggle="toggleCalendarbar"
            :name="$t('tools.project.recordings.sidebar.title')" height="87%" maxHeight="640px"
            v-on:close="toggleCalendarbar = false">
            <template v-slot:content>
              <div v-if="presetDates != null">
                <Tabs :eager="true" :hideTabs="singleRecording" fullHeight class="mt-1" :tabs="settingsTabs">
                  <template v-slot:before>
                    <DatePicker :min="presetDates.min" :max="presetDates.max" class="mt-0"
                      :dateEvent="!singleRecording ? selectedDates.end : null" :datetime="selectedDates.start"
                      :disablePrevious="true" :allowed="presetDates.allowed" v-on:changed="updatedStartDate" />
                    <v-divider class="mr-4 ml-4 mb-3 mt-4" />
                    <div class="ml-5 font-weight-bold" style="font-size: 16px">
                      {{ $t('tools.project.recordings.sidebar.timestamp') }}
                      <span class="font-weight-medium ml-1">({{
                        $helper.formatDate(selectedDates.start, false, false)
                        }})</span>
                    </div>
                    <TimePicker class="pb-2" :datetime="selectedDates.start" :allowed="allowedStartHours"
                      v-on:changed="updatedStartTime" />
                  </template>
                  <template v-slot:after>
                    <DatePicker :min="presetDates.min" :max="presetDates.max" class="mt-3"
                      :dateEvent="selectedDates.start" :datetime="selectedDates.end" :disablePrevious="true"
                      :allowed="presetDates.allowed" v-on:changed="updatedEndDate" />
                    <v-divider class="mr-4 ml-4 mb-3 mt-4" />
                    <div class="ml-5 font-weight-bold" style="font-size: 16px">
                      {{ $t('tools.project.recordings.sidebar.timestamp') }}
                      <span class="font-weight-medium ml-1">({{
                        $helper.formatDate(selectedDates.end, false, false)
                        }})</span>
                    </div>
                    <TimePicker class="pb-2" :datetime="selectedDates.end" :allowed="allowedEndHours"
                      v-on:changed="updatedEndTime" />
                  </template>
                </Tabs>
              </div>
            </template>
          </SideDialog>

          <v-dialog v-model="shareDataDialog.status" width="500">
            <ShareDialog v-on:close="shareDataDialog.status = false" :dynamic="false"
              :shareObj="shareDataDialog.data" />
          </v-dialog>
        </div>
      </fullscreen>
    </div>
    <div v-else style="position: relative; height: 100%; width: 100%">
      <v-row style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        ">
        <v-icon color="grey" class="mr-2" size="18">mdi-image-off-outline</v-icon>
        <span class="grey--text" style="font-size: 14px">{{
          $t('tools.project.dashboard.error.noImages')
          }}</span>
      </v-row>
    </div>
  </div>
</template>

<script>
import SideDialog from '@components/dialog/SideDialog.vue';
import DatePicker from '@components/datetime/DatePicker.vue';
import TimePicker from '@components/datetime/TimePicker.vue';
import Tabs from '@components/list/Tabs.vue';
import IconButton from '@components/button/IconButton.vue';
// import TextButton from "@components/button/TextButton.vue"
import ShareDialog from '@/components/tools/project/helper/ShareDialog.vue';
import WeatherCard from './WeatherCard.vue';

// import RecordingsPage from '@components/pages/RecordingsPage.vue'

export default {
  name: 'RecordingsPageTemplate',
  components: {
    SideDialog,
    DatePicker,
    TimePicker,
    Tabs,
    IconButton,
    ShareDialog,
    WeatherCard,
  },

  props: {
    loadedTimelapse: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 2,
    },
    singleRecording: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'slider',
    },
  },

  data() {
    return {
      weatherData: null,
      zoomLevel: this.zoom * 10,

      currentDateTab: 0,
      settingsTabs: this.$t('tools.project.recordings.sidebar.tabs'),
      selectedDates: {
        start: null, //new Date(new Date().setDate(new Date().getDate() - 7)),
        end: null, //new Date()
      },

      selectedPresetIndex: 0,
      toggleBottombar: false,
      toggleFullscreen: false,
      toggleCalendarbar: false,
      toggleMotionPlay: false,
      presetSelectorMenu: false,
      presetDates: null,
      loadingPresetDates: false,
      loadingPresetData: false,
      presetError: false,
      shareDataDialog: { status: false, data: null },
      currentStartImage: null,
      currentEndImage: null,
      motionPlayAvailable: false,

      nextMinImage: { start: null, end: null },
      prevMinImage: { start: null, end: null },
      nextImage: null,
      prevImage: null,

      loadingNextDate: false,
    };
  },

  watch: {
    toggleMotionPlay() {
      this.$emit('motionplay', this.toggleMotionPlay);
      if (this.toggleMotionPlay) {
        this.toggleCalendarbar = false;
        this.toggleBottombar = false;
      }
    },
    toggleBottombar() {
      if (this.toggleBottombar) {
        this.toggleCalendarbar = false;
        this.toggleMotionPlay = false;
      }
    },
    toggleCalendarbar() {
      if (this.toggleCalendarbar) {
        this.toggleBottombar = false;
        this.toggleMotionPlay = false;
      }
    },
    zoom() {
      this.zoomLevel = this.zoom * 10;
    },
    zoomLevel() {
      this.$emit('zoomlevel', this.zoomLevel / 10);
    },
    loadingPresetDates() {
      if (this.loadingPresetDates == true)
        this.toggleCalendarbar, (this.toggleBottombar = false);
    },
    selectedPresetIndex: {
      handler() {
        this.getPresetDates();
      },
      immediate: true,
    },
  },

  computed: {
    project() {
      return this.$store.getters.project;
    },
    prevImageTime() {
      return this.currentDateTab == 0
        ? this.prevMinImage.start
        : this.prevMinImage.end;
    },

    nextImageTime() {
      return this.currentDateTab == 0
        ? this.nextMinImage.start
        : this.nextMinImage.end;
    },
    allowedStartHours() {
      if (this.selectedDates.start == null) return [];
      return this.presetDates.data.reduce((a, b) =>
        new Date(a.date).toDateString() ==
          this.selectedDates.start.toDateString()
          ? a
          : b,
      );
    },
    allowedEndHours() {
      if (this.selectedDates.end == null) return [];
      return this.presetDates.data.reduce((a, b) =>
        new Date(a.date).toDateString() == this.selectedDates.end.toDateString()
          ? a
          : b,
      );
    },
    presets() {
      if (
        this.$store.getters.project == null ||
        this.$store.getters.project.presets == null
      )
        return;
      return this.$store.getters.project.presets.data;
    },
  },

  methods: {
    showShareDialog() {
      if (
        this.currentStartImage == null ||
        this.currentStartImage.image == null
      )
        return;
      this.shareDataDialog.status = true;
      if (this.singleRecording == true)
        this.shareDataDialog.data = {
          shareType: 'image',
          name: this.$t('tools.project.recordings.share.image'),
          shareUrl: `link=${encodeURIComponent(
            this.currentStartImage.image.downloadUrl,
          )}&expiresAt=${new Date(
            this.currentStartImage.image.expiresAt,
          ).toISOString()}`,
          presetId: this.currentStartImage.presetId,
          date: this.currentStartImage.captureTime,
          type: 0,
          imageUrl: this.currentStartImage.image.downloadUrl,
          thumbnailUrl: this.currentStartImage.thumbnail.downloadUrl,
          //timelapseUrl: this.currentStartImage.timelapse.timelapse.downloadUrl,
        };
      else
        this.shareDataDialog.data = {
          shareType: this.type,
          name: this.$t('tools.project.recordings.share.multiple'),
          shareUrl: `start=${encodeURIComponent(
            this.currentStartImage.image.downloadUrl,
          )}&end=${encodeURIComponent(
            this.currentEndImage.image.downloadUrl,
          )}&startTime=${+new Date(
            this.currentStartImage.captureTime,
          )}&endTime=${+new Date(
            this.currentStartImage.captureTime,
          )}&expiresAt=${new Date(
            this.currentEndImage.image.expiresAt,
          ).toISOString()}`,
          presetId: this.currentStartImage.presetId,
          dateA: this.currentStartImage.captureTime,
          dateB: this.currentEndImage.captureTime,
          type: 1,
          imageAUrl: this.currentStartImage.image.downloadUrl,
          imageBUrl: this.currentEndImage.image.downloadUrl,
          thumbnailAUrl: this.currentStartImage.thumbnail.downloadUrl,
          thumbnailBUrl: this.currentEndImage.thumbnail.downloadUrl,
        };
    },

    switchImage(date) {
      this.selectedDates.start = new Date(date);
        this.updateStartImage();
    },
    switchImageEvent(minutes) {
      var currentDate =
        this.currentDateTab == 0
          ? this.selectedDates.start
          : this.selectedDates.end;
      var dateHandle = new Date(currentDate);

      dateHandle.setMinutes(currentDate.getMinutes() + minutes);

      if (this.currentDateTab == 0) {
        this.selectedDates.start = new Date(dateHandle);
        this.updateStartImage();
      } else {
        this.selectedDates.end = new Date(dateHandle);
        this.updateEndImage();
      }
    },
    async reloadPresetData() {
      this.loadingPresetData = true;
      await this.$api.project.latestImages(this.$route.params.projectId);
      this.loadingPresetData = false;
    },
    updatedStartDate(eventHandle) {
      if (this.selectedDates.start == null)
        return (this.selectedDates.start = new Date(eventHandle));

      var dateHandle = new Date(eventHandle);
      dateHandle.setHours(this.selectedDates.start.getHours());
      this.selectedDates.start = dateHandle;
    },

    updatedEndDate(eventHandle) {
      if (this.selectedDates.end == null)
        return (this.selectedDates.end = new Date(eventHandle));

      if (this.singleRecording == true) return;

      var dateHandle = new Date(eventHandle);
      dateHandle.setHours(this.selectedDates.end.getHours());
      this.selectedDates.end = dateHandle;
    },

    updatedStartTime(eventHandle) {
      this.selectedDates.start.setHours(eventHandle);
      // this.selectedDates.start.setMinutes(5)
      this.updateStartImage();
    },

    updatedEndTime(eventHandle) {
      if (this.singleRecording == true) return;
      this.selectedDates.end.setHours(eventHandle);
      //  this.selectedDates.end.setMinutes(5)
      this.updateEndImage();
    },

    async updateEndImage() {
      if (
        this.loadingPresetData == true ||
        this.presets == null ||
        this.selectedDates.end == null
      )
        return;
      const presetObj = this.presets[this.selectedPresetIndex];
      if (presetObj == null) return;

      this.loadingNextDate = true;
      this.$emit('load-start', true);
      this.motionPlayAvailable = false;
      const resp = await this.$api.project.imageByHour(
        this.$route.params.projectId,
        presetObj.cameraId,
        presetObj.presetId,
        this.selectedDates.end,
        false,
      );

      if (
        resp.success == false &&
        (resp.data == null ||
          resp.data.nextImageTime == null ||
          resp.data.image == null ||
          resp.data.image.image == null)
      ) {
        this.$emit('load-end', true);
        this.currentEndImage == null;
        return;
      }

      this.selectedDates.end = new Date(resp.data.image.captureTime);

      if (resp.data.nextImageTime != null) {
        var nextImageDiff = Math.round(
          (new Date(resp.data.nextImageTime) -
            new Date(this.selectedDates.start)) /
          60000,
        ); // min

        if (isNaN(nextImageDiff) || nextImageDiff > 50)
          this.nextMinImage.end = null;
        else this.nextMinImage.end = nextImageDiff;
      } else {
        this.nextMinImage.end = null;
      }

      if (resp.data.previousImageTime != null) {
        var prevImageDiff = Math.round(
          (new Date(this.selectedDates.end) -
            new Date(resp.data.previousImageTime)) /
          60000,
        ); // min

        if (isNaN(prevImageDiff) || prevImageDiff > 50)
          this.prevMinImage.end = null;
        else this.prevMinImage.end = prevImageDiff;
      } else {
        this.prevMinImage.end = null;
      }

      this.$emit('update-end', resp.data.image.image.downloadUrl);
      this.currentEndImage = {
        ...resp.data.image,
        //timelapse: resp.data.timelapse || null,
      };

      this.loadingNextDate = false;
    },

    async updateStartImage() {
      console.log("UPDATE START IMAGE")
      if (
        this.loadingPresetData == true ||
        this.presets == null ||
        this.selectedDates.start == null
      )
        return;

      const presetObj = this.presets[this.selectedPresetIndex];
      if (presetObj == null) return;

      this.loadingNextDate = true;
      this.$emit('load-start', true);
      this.motionPlayAvailable = false;
      const resp = await this.$api.project.imageByHour(
        this.$route.params.projectId,
        presetObj.cameraId,
        presetObj.presetId,
        this.selectedDates.start,
      );

      if (
        resp.success == false &&
        (resp.data == null ||
          resp.data.nextImageTime == null ||
          resp.data.image == null ||
          resp.data.image.image == null)
      ) {
        this.$emit('load-end', true);
        this.currentStartImage == null;
        return;
      }

      this.selectedDates.start = new Date(resp.data.image.captureTime);



      if (resp.data.nextImageTime != null) {
        this.nextImage = resp.data.nextImageTime;
        var nextImageDiff = Math.round(
          (new Date(resp.data.nextImageTime) -
            new Date(this.selectedDates.start)) /
          60000,
        ); // min
        if (isNaN(nextImageDiff) || nextImageDiff > 50)
          this.nextMinImage.start = null;
        else this.nextMinImage.start = nextImageDiff;
      } else {
        this.nextMinImage.start = null;
      }

      if (resp.data.previousImageTime != null) {
        this.prevImage = resp.data.previousImageTime;
        var prevImageDiff = -Math.round(
          (new Date(this.selectedDates.start) -
            new Date(resp.data.previousImageTime)) /
          60000,
        ); // min

        if (isNaN(prevImageDiff) || prevImageDiff > 50)
          this.prevMinImage.start = null;
        else this.prevMinImage.start = prevImageDiff;
      } else {
        this.prevMinImage.start = null;
      }

      this.$emit('update-start', resp.data.image.image.downloadUrl);
      this.currentStartImage = {
        ...resp.data.image,
        //timelapse: resp.data.timelapse || null,
      };

      // if (this.weatherData != null) {
      //   var index = this.weatherData.some(date => ((+ new Date(date)) - (+ new Date(this.selectedDates.start))))
      // }

      this.loadingNextDate = false;
    },

    async getPresetDates() {
      this.presetError = false;
      this.toggleMotionPlay = false;

      this.loadingPresetDates = true;
      this.$emit('load-start', true);

      var resp;
      try {
        resp = await this.$api.project.presetDates(
          this.presets[this.selectedPresetIndex].cameraId,
          this.$route.params.projectId,
          this.presets[this.selectedPresetIndex].presetId,
        );
      } catch {
        this.presetError = true;
        this.loadingPresetDates = true;
        return;
      }

      if (resp == null) {
        this.presetError = true;
        return;
      }

      resp = resp.map((date, index) => {
        date.hours =
          (date.hours << 1) |
          ((((index > 0 ? resp[index - 1].hours : 0) >> 24) & 1) << 0);
        return date;
      });
      var dates = resp
        .map((date) => date.date)
        .sort((a, b) => Date.parse(b) - Date.parse(a));

      this.presetDates = {
        max: new Date(Math.max(...dates)),
        min: new Date(Math.min(...dates)),
        allowed: dates.map((date) => {
          return new Date(date).toISOString().substring(0, 10);
        }),
        data: resp,
      };

      if (this.selectedDates.start == null || this.selectedDates.end == null) {
        this.updatedStartDate(this.presetDates.max);
        // this.updatedStartTime(12);
        this.updatedEndDate(this.presetDates.max);
        // this.updatedEndTime(12)
      } else {
        var tempDateHandle;

        try {
          tempDateHandle = dates.map((d) =>
            Math.abs(this.selectedDates.start - new Date(d).getTime()),
          );
          this.updatedStartDate(
            dates[tempDateHandle.indexOf(Math.max(...tempDateHandle))],
          );
        } catch {
          this.updatedStartDate(this.presetDates.max);
        }

        // this.updatedStartTime(12);

        try {
          tempDateHandle = dates.map((d) =>
            Math.abs(this.selectedDates.end - new Date(d).getTime()),
          );

          this.updatedEndDate(
            dates[tempDateHandle.indexOf(Math.min(...tempDateHandle))],
          );
          if (this.selectedDates.start == this.selectedDates.end) {
            this.updatedEndDate(this.presetDates.max);
          }
        } catch {
          this.updatedEndDate(this.presetDates.max);
        }

        // this.updatedEndTime(12)
      }

      this.loadingPresetDates = false;
    },
  },
};
</script>

<style>
.image-container {
  position: absolute;
  top: 50%;
  width: 100%;
  aspect-ratio: 16/9;
  transform: translateY(-50%);
  background-color: rgba(230, 230, 230, 0.5);
}

.recording-container {
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.zoombar {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 50;
  opacity: 1;
  border-radius: 10px;
  height: 36px;
  width: 175px;
  background-color: white;
  overflow: hidden;
}

.toolbar {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5px;
  z-index: 50;
  border-radius: 10px;
  height: 36px;
  background-color: white;
  overflow: hidden;
}

.no-preview {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preset-selector {
  width: 260px;
  height: 36px;
  transition: background-color 200ms linear;
  padding-top: 6px;
  padding-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.preset-selector:hover {
  background-color: #eeeeee;
  transition: background-color 200ms linear;
}

.error-text {
  width: 260px;
  height: 36px;
  transition: background-color 200ms linear;
  padding-top: 4px;
  cursor: default;
  padding-left: 10px;
  padding-right: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white !important;
  font-size: 13px;
}

.motionbar {
  position: absolute;
  bottom: 20px;
  left: 50%;
  background-color: white;
  height: 56px;
  border-radius: 10px;
  width: 564px;
  opacity: 1;
  overflow: hidden !important;
  transform: translateX(-50%);
  z-index: 150;
}

.bottombar {
  position: absolute;
  bottom: 20px;
  left: 50%;
  background-color: white;
  height: 76px;
  border-radius: 10px;

  opacity: 1;
  overflow: hidden !important;
  transform: translateX(-50%);
  z-index: 150;
}

.tab-button {
  height: 28px !important;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 14px !important;
}
</style>
