<template>
  <!-- Weather button -->
  <v-menu
    v-model="weatherCard"
    :close-on-content-click="false"
    :nudge-width="324"
    :nudge-bottom="3"
    offset-y
    transition="none"
  >
    <template v-slot:activator="{on, attrs}">
      <div
        v-bind="attrs"
        v-on="on"
        :style="disabled ? 'pointer-events: none!important' : ''"
      >
        <v-btn
          :disabled="disabled"
          style="border-left: 1px solid rgba(0, 0, 0, 0.1)"
          :ripple="false"
          text
          tile
        >
          <v-icon :color="weatherCard ? 'primary' : ''" size="21"
            >mdi-weather-partly-cloudy</v-icon
          >
        </v-btn>
      </div>
    </template>

    <v-card
      v-if="date != null"
      width="390"
      height="250"
      oncontextmenu="return false;"
      style="
        cursor: default !important;
        user-select: none;
        overflow: hidden !important;
        position: relative;
      "
    >
      <div v-if="!loadingWeather && weatherData != null">
        <v-row class="ma-0 pt-2" align="center">
          <img class="mr-4 ml-3" width="52" height="52" :src="getImgData()" />
          <div>
            <span style="font-size: 20px"
              ><span class="ma-0 font-weight-bold">{{
                Math.round(weatherData.temperature)
              }}</span>
              °C</span
            >
            <div>{{ $helper.formatDate(date) }}</div>
          </div>
        </v-row>
        <v-divider class="ml-2 mr-2 mt-3 mb-2" />
        <div v-if="weatherData != null">
          <TableListRow class="ml-2 mr-2">
            <template v-slot:key>
              {{ $t('tools.project.recordings.weather.precipitation') }}
            </template>
            <template v-slot:value>
              <span v-if="weatherData.precipitation == null">-</span>
              <span v-else
                >{{ weatherData.precipitation
                }}<span class="ml-1">mm</span></span
              >
            </template>
          </TableListRow>
          <TableListRow class="ml-2 mr-2">
            <template v-slot:key>
              {{ $t('tools.project.recordings.weather.pressure') }}
            </template>
            <template v-slot:value>
              <span v-if="weatherData.pressure_msl == null">-</span>
              <span v-else
                >{{ weatherData.pressure_msl
                }}<span class="ml-1">hPa</span></span
              >
            </template>
          </TableListRow>

          <TableListRow class="ml-2 mr-2">
            <template v-slot:key>
              {{ $t('tools.project.recordings.weather.windSpeed') }}
            </template>
            <template v-slot:value>
              <span v-if="weatherData.wind_speed == null">-</span>
              <span v-else
                >{{ weatherData.wind_speed
                }}<span class="ml-1">km/h</span></span
              >
            </template>
          </TableListRow>

          <TableListRow class="ml-2 mr-2">
            <template v-slot:key>
              {{ $t('tools.project.recordings.weather.visibility') }}
            </template>
            <template v-slot:value>
              <span v-if="weatherData.visibility == null">-</span>
              <span v-else
                >{{ weatherData.visibility }}<span class="ml-1">m</span></span
              >
            </template>
          </TableListRow>

          <div style="font-size: 13px" class="ml-4 pt-5 pb-1">
            <span style="color: rgba(0, 0, 0, 0.7)" class="mr-1">
              {{ $t('tools.project.recordings.weather.source') }}:</span
            >
            <a href="https://www.dwd.de" style="text-decoration: none"
              ><span class="links">Deutscher Wetterdienst</span></a
            >
          </div>
        </div>
      </div>
      <div v-else-if="loadingWeather == false && weatherData == null">
        <div
          class="center grey--text"
          style="font-size: 14px; justify-content: center; text-align: center"
        >
          {{ $t('tools.project.recordings.weather.noData') }}
        </div>
      </div>
      <div v-else>
        <v-progress-circular
          indeterminate
          width="3"
          size="40"
          color="primary"
          class="center"
        ></v-progress-circular>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import TableListRow from '@components/table/TableListRow.vue';

export default {
  name: 'WeatherCard',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    coords: {
      type: Object,
      required: true,
    },
    date: {
      required: true,
    },
  },
  data() {
    return {
      weatherCard: false,
      loadingWeather: false,
      weatherData: null,
    };
  },
  watch: {
    weatherCard() {
      if (this.weatherCard == true) return this.getWeather();
      else this.weatherData = null;
    },
  },
  methods: {
    getImgData() {
      var images = require.context(
        '../../../../assets/weather/',
        false,
        /\.png$/,
      );
      if (images == null) return;
      else return images(`./${this.weatherData.icon}.png`);
    },
    async getWeather() {
      this.loadingWeather = true;
      this.weatherData = null;

      this.$nextTick(async () => {
        if (
          this.coords.lat == null ||
          this.coords.lng == null ||
          this.date == null
        )
          return;
        try {
          var resp = await this.$api.project.weather(
            this.date.toISOString(),
            this.coords.lat,
            this.coords.lng,
          );

          if (resp != null && resp.data != null) {
            this.weatherData = JSON.parse(JSON.stringify(resp.data.weather[0]));
          } else throw new Error();
        } catch (error) {
          this.weatherData = null;
        }

        setTimeout(() => {
          this.loadingWeather = false;
        }, 500);
      });
    },
  },
  components: {TableListRow},
};
</script>

<style>
.links {
  user-select: none;
  font-size: 13px;
  cursor: pointer !important;
  text-decoration: none;
  color: black;
}
.links:hover {
  color: var(--v-primary-base);
}
.center {
  position: absolute;
  top: 50%;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
