<template>

  <v-hover :disabled="noHover" v-slot="{ hover }">
    <v-row :style="hover ? 'background-color: rgba(200, 200, 200, .05)' : ''" align="start" class="ma-0 pa-1 mt-0" style="border-radius: 10px">
      <div v-show="!noKey" class="ml-1 font-weight-medium" style="width: 150px; font-size: 15px">
        <slot name="key" />:
      </div>
      <div class="font-weight-normal" style="max-width: calc(100% - 160px); font-size: 15px">
        <v-row class="ma-0" align="center">

          <slot  name="value" :hover="hover" />


          <v-fade-transition>
            <div class="ml-2" v-show="copyable && hover">
							<v-icon class="mb-1" size="14" v-on:click="copyToClipboard()">mdi-content-copy</v-icon>
            </div>
          </v-fade-transition>
        </v-row>
      </div>
    </v-row>
  </v-hover>

</template>

<script>

  export default {
    props: {
      noKey: {
        type: Boolean, 
        default: false,
      },
      noHover: {
        type: Boolean, 
        default: false
      },
      copyable: {
        type: Boolean,
        default: false
      },
			copyValue: {
				type: String,
				default: ''
			}
    },


    methods: {
      copyToClipboard() {
			
          this.$helper.copy2Clipboard(this.copyValue)
          // this.$toast.info('In Zwischenablage gelegt')
       
      }
    }
  }

</script>
