<template>

  <div class="noselect mt-2">

      <v-row class="ma-0 ml-5">

        <div v-for="(n, i) in 24" :key="n">
          <v-badge bottom left overlap
                   :value="(dateEvent != null && dateEvent.getHours() != null ? dateEvent.getHours() == i : false)"
                   dot
                   offset-x="21"
                   color="#FFC007"
                   offset-y="10">

            <v-btn :disabled="checkExistence(i)"
                   :ripple="false"
                   height="32"
                   width="32"
                   style="padding: 0 !important; font-size: 12 !important; margin-right: 7px"
                   class=" mt-2 font-weight-medium"
                   :class="
                  selectedHour == i
                    ? 'primary white--text'
                    : 'transparent black--text'
                "
                :style="selectedHour == i ? 'opacity: .8' :''"
                   icon
                   small
                   v-on:click="changeHour(i)"><span style="font-size: 12 !important">{{ i }}</span></v-btn>
          </v-badge>
        </div>
      </v-row>
  </div>

</template>

<script>

  export default {
    props: ['datetime', 'dateEvent', 'disablePrevious', 'title', 'allowed'],
    data() {
      return {
        selectedHour: 12,
        lastSubmitted: {
          time: new Date().toISOString().substr(0, 10),
          date: new Date().toISOString().substr(0, 10)
        }
      }
    },

    mounted() {
     
    },
    watch: {
      allowed: {
        handler() {
           if (this.datetime != null) {

              var setHour = this.datetime.getHours()
              
              if (!this.checkExistence(setHour)) this.selectedHour = setHour; 
              else {
                
                var closestHour = 0; 
                for (let index = 0; index < 24; index++) {
                  if (!this.checkExistence(index)) {
                    if ((setHour - index) / (setHour - closestHour) < 1) closestHour = index
                  }
                }

                setHour = closestHour
              }
              
        this.selectedHour = setHour
        this.$emit('changed', setHour)
   
      }
        }, immediate: true, deep: true
      },
    
    
    },

    computed: {
      checkExistence() {
        return hourHandle => {
            return !((this.allowed.hours & (1 << hourHandle)) != 0)
        }
      }
    },

    
    methods: {
      formatDate() {
        if (this.datetime == undefined || this.datetime.date == undefined) {
          return ''
        }
        var imageDate = new Date(this.datetime.date)
        const options = {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }

        return `(${imageDate.toLocaleDateString(`${this.$i18n.locale}-${this.$i18n.locale}`, options)})`
      },

      changeHour(hour) {
          // TODO Undo Date selection
          this.$emit('changed', hour)
          this.selectedHour = hour

      }
    }
  }

</script>

<style scoped>

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

</style>
