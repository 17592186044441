<template>

  <div>

    <v-col class="ma-0" style="padding: 0px!important">

      <!-- <span class="ml-4">Bitte wählen Sie ein Datum aus.</span> -->
      <v-date-picker :first-day-of-week="1"
                     :min="(new Date(min)).toISOString()"
                     :allowed-dates="allowedDates"
                     :max="(new Date(max)).toISOString()"
                     :events="[(new Date(dateEvent)).toISOString().substr(0, 10)]"
                     no-title
                     color="primary"
                     width="300"
                     :locale="`${$i18n.locale}-${$i18n.locale}`"
                     v-model="pickerValue"
                     v-on:change="changedDate"></v-date-picker>
    </v-col>
  </div>

</template>

<script>

  export default {
    props: {
      allowed: {
        type: Array,
        default: null
      },
      min: {
        type: Date,
      },
      max: {
        type: Date,
      },
      dateEvent: {
        type: Date,
        required: false,
        default: null
      },
      datetime: {
        type: Date,
        required: true
      },
      disablePrevious: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    data() {
      return {
        pickerValue: new Date(this.datetime).toISOString().substr(0, 10)
      }
    },

    watch: {
      datetime: {
        handler() {

          if (this.allowed.indexOf(this.datetime.toISOString().substring(0, 10) != -1)) {
            // var dateHandle = new Date(this.datetime)
            var tzoffset = (new Date(this.datetime)).getTimezoneOffset() * 60000; //offset in milliseconds
            this.pickerValue = (new Date((new Date(this.datetime)) - tzoffset)).toISOString().substring(0, 10)
    
          }
          
        },
        deep: true,
        immediate: true
      }
    },

    methods: {
      changedDate() {

        this.$emit('changed', this.pickerValue)
      },

      allowedDates(val) {
        // // console.l("ALLOWED", this.allowed)
        try {
          if (
            this.disablePrevious == true &&
            this.dateEvents != null &&
            this.dateEvents.length > 0 &&
            Date.parse(val) < Date.parse(this.dateEvents[0])
          ) {
            return false
          } else {
            if (this.allowed == null || this.allowed.length == 0) return false
            return this.allowed.indexOf(val) !== -1
          }
        } catch {
          return
        }
      }
    }
  }

</script>

<style scoped>

  .headline-underline {
    position: absolute;

    bottom: 0px;
    background-color: var(--v-primary-base);
    width: 100%;
    height: 3px;
    border-radius: 2px;
  }

</style>
