<template>
  <div style="position: relative; height: 100%; overflow: hidden">
    <RecordingsPage
      v-on:load-start="imageLoading = true"
      v-on:load-end="imageLoading = false"
      v-on:update-start="updateImageEvent"
      v-on:timelapse-update="timelapseUpdateEvent"
      v-on:motionplay="motionPlayEvent"
      v-on:zoomlevel="zoomLevelEvent"
      :loadedTimelapse="
        motionPlay && motionPlayFrame != null && motionMaxFrame != null
      "
      :zoom="zoomScale"
      :singleRecording="true"
    >
      <template v-slot:motionbar>
        <div>
          <div
            v-if="
              motionPlay && motionPlayFrame != null && motionMaxFrame != null
            "
          >
            <v-row class="ma-0" align="center">
              <span class="grey--text ml-3 mr-1" style="font-size: 14px">{{
                new Date(timelapseObj.startTime).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })
              }}</span>
              <v-slider
                class="mt-3 mb-3"
                dense
                hide-details
                :min="0"
                :max="motionMaxFrame"
                v-model="motionPlayFrame"
              />
              <span class="grey--text mr-3 ml-1" style="font-size: 14px">
                {{
                  new Date(timelapseObj.endTime).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                }}</span
              >
            </v-row>
          </div>
        </div>
      </template>

      <template v-slot:image>
        <div style="position: relative; height: 100%; width: 100%">
          <div
            :style="
              !(motionPlay && motionPlayFrame != null && motionMaxFrame != null)
                ? ''
                : 'z-index: 0'
            "
            style="position: absolute; width: 100%; width: 100%; z-index: 10"
          >
            <ImageZoom
              :id="1"
              :disabled="loading"
              :zoomScale="zoomScale"
              :zoomPreload="true"
              :src="imageUrl || ''"
              :hideHint="true"
              v-on:load="loadedImageEvent"
            />
          </div>

          <div
            v-show="motionPlay"
            v-if="motionPlay && timelapseObj != null"
            style="
              position: absolute;
              width: 100%;
              height: 100%;
              cursor: default !important;
            "
          >
            <video
              :style="
                motionPlayFrame == null || motionMaxFrame == null
                  ? 'opacity: 0!important;'
                  : ''
              "
              v-on:canplaythrough="loadedTimelapseEvent"
              ref="video"
              class="video-player"
              muted
            >
              <source
                :src="`${timelapseObj.timelapse.downloadUrl}`"
                type="video/mp4"
                codecs="avc1.4d002a, mp4a.40.2"
              />
            </video>
          </div>

          <v-overlay :value="loading" absolute style="z-index: 50 !important">
            <v-progress-circular
              v-if="loading"
              indeterminate
              width="3"
              size="40"
              color="primary"
              class="center"
            ></v-progress-circular>
          </v-overlay>
        </div>
      </template>
    </RecordingsPage>
  </div>
</template>

<script>
import RecordingsPage from '@/components/tools/project/recordings/RecordingsPageTemplate.vue';
import ImageZoom from '@components/image/ImageZoom.vue';

export default {
  name: 'ProjectViewRecordings',
  components: {
    RecordingsPage,
    ImageZoom,
  },

  data() {
    return {
      loadedTimelapse: false,
      zoomScale: 2.5,
      imageLoading: false,
      imageLoaded: false,
      imageUrl: null,
      loadedTimeout: null,
      syncData: null,
      motionPlay: false,
      timelapseObj: null,
      motionPlayFrame: 0,
      motionMaxFrame: 0,
    };
  },

  computed: {
    loading() {
      return !(!this.imageLoading && this.imageLoaded);
    },
  },

  watch: {
    motionPlayFrame: {
      handler() {
        if (this.$refs.video == null || this.motionPlayFrame == null) return;
        // console.log("UPDATE")
        this.$refs.video.currentTime = this.motionPlayFrame * (1 / 30);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    timelapseFrameLength() {
      if (this.$refs.video == null || this.motionPlay == false) return 0;
      this.motionMaxFrame = Math.ceil(
        (this.$refs.video.duration || 1) / (1 / 30),
      );
    },
    zoomLevelEvent(zoomLevel) {
      this.zoomScale = zoomLevel;
    },
    motionPlayEvent(motionPlayStatus) {
      this.motionPlay = motionPlayStatus;
      this.$nextTick(() => {
        if (motionPlayStatus == false) {
          this.motionMaxFrame = null;
        }
      });
    },
    syncImageEvent(syncObj) {
      if (this.loading) return;
      this.syncData = syncObj;
    },

    timelapseUpdateEvent(timelapseUrl) {
      this.motionMaxFrame = null;
      this.loadedTimelapse = false;
      this.timelapseObj = timelapseUrl;
    },

    updateImageEvent(imageUrlHandle) {
      this.imageLoaded = false;
      this.imageUrl = `${imageUrlHandle}&lastmod=${+new Date()}`;

      this.imageLoading = false;
      this.loadedTimeout = setTimeout(() => {
        this.imageLoaded = true;
      }, 5000);
    },

    loadedImageEvent(src) {
      if (src != this.imageUrl) return;
      this.imageLoaded = true;
      clearTimeout(this.loadedTimeout);
    },

    loadedTimelapseEvent() {
      if (this.$refs.video == null) return;
      setTimeout(() => {
        this.loadedTimelapse = true;
      }, 500);
      /**
       * diffTime = selectedTime - startTime
       *
       *
       *
       *
       */
      if (this.motionMaxFrame == null) {
        // console.log("MO", this.timelapseObj.startTime)
        this.timelapseFrameLength();
        this.motionPlayFrame =
          ((new Date(this.timelapseObj.date) -
            new Date(this.timelapseObj.startTime)) /
            3600000) *
          12;
      }
    },
  },
};
</script>

<style>
.clipped-image-2 {
  width: 50%;
  -webkit-clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
}
.clipped-image-1 {
  width: 50%;
  left: -50%;
  -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
}
.video-player {
  height: 100%;
  width: 100%;
}
</style>
